import React, { useState, useEffect } from 'react'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import CircularProgress from '@material-ui/core/CircularProgress'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import { apis } from '../../../config/apiConfig'

import scss from '../Users.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'
import ReactSelect from '../../../components/ReactSelect/ReactSelect'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import AsyncFetch from '../../../utilities/AsyncFetch'
import * as utils from '../../../utilities/util'
import { useTranslation } from 'react-i18next'

function SimpleDialog({ userID, userRole, accounts, visible, doUpdate }) {
  const { t } = useTranslation()

  const [open, setOpen] = useState(true)

  const [fetching, setFetching] = useState(false)

  // Role Fetching
  const [roleLoading, setRoleLoading] = useState(false)
  const [roleFetchObjects, setRoleFetchObjects] = useState(null)
  const [roles, setRoles] = useState([])

  const [showRoleDropdown, setRoleDropdown] = useState(false)

  // Fields and their States
  const [emailAddressArray, setEmailAddressArray] = useState([])
  const [userRoleState, setRole] = useState(
    userRole == null ? 'full' : userRole
  )
  const [selectedMaps, setSelectedMaps] = useState([])
  const [selectedMapValue, setSelectedMapValue] = useState([])
  const [organizationState, setOrganizationState] = useState(null)

  // AsyncFetch state variables
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mapOptions, setMapOptions] = useState(null)

  const handleSave = () => {
    const url = apis.apiDatabase.uri + 'account/user/add'
    const method = 'POST'
    const body = {
      emailArray: emailAddressArray,
      mapArray: selectedMapValue,
      accountID: organizationState,
      role: userRoleState,
    }

    setFetching('userAdd')
    setFetchObjects([{ url, method, body }])
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  const fetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        if (fetching === 'maps') {
          const optionsArr = res.data.map(item => ({
            label: item.name,
            value: item.id,
          }))

          setMapOptions(optionsArr)
        }

        if (fetching === 'userAdd') {
          if (utils.verifyResult(results[0])) doUpdate()

          handleOnClose()
        }
      }
    })

    setFetching(false)
    setFetchObjects(null)
  }

  // ROLE FETCH
  const updateRoles = () => {
    const method = 'GET'
    const url = apis.apiDatabase.uri + 'roles'
    const body = {}

    setRoleFetchObjects([{ url, method, body }])
    setRoleLoading(true)
  }

  const roleFetchFinished = results => {
    return results.forEach(result => {
      if (utils.verifyResult(result)) {
        setRoles(utils.resultReturn(result))

        setRoleLoading(false)
      }

      setRoleFetchObjects(null)
    })
  }

  const getRoleName = () => {
    const role = roles.find(r => r.slug === userRoleState)
    return role ? role.name : '-'
  }

  const verifyForm = () => {
    return (
      !roleLoading &&
      emailAddressArray.length &&
      organizationState &&
      selectedMaps.length
    )
  }

  const handleEmail = e => {
    let emails = e.target.value
    emails = emails.replace(/[;\n]/g, ',').split(',')
    const procArray = emails.map(email => {
      return email.toLowerCase().trim()
    })

    setEmailAddressArray(procArray)
  }

  const handleOrg = e => setOrganizationState(e.target.value)

  const handleRole = role => {
    setRole(role)
    setRoleDropdown(false)
  }
  //

  useEffect(() => {
    updateRoles()
  }, [])

  useEffect(() => {
    if (mapOptions && mapOptions.length) setSelectedMaps([mapOptions[0]])
  }, [mapOptions])

  useEffect(() => {
    if (selectedMaps && selectedMaps.length)
      setSelectedMapValue([selectedMaps[0].value])
  }, [selectedMaps])

  useEffect(() => {
    setSelectedMaps([])
    if (organizationState) {
      if (!utils.isNumeric(organizationState)) {
        setMapOptions([])
        setOrganizationState(null)
        return
      }
      const url = apis.apiDatabase.uri + 'account/maps'
      const method = 'POST'
      const body = {
        accountID: organizationState,
      }

      setFetching('maps')
      setFetchObjects([{ url, method, body }])
    }
  }, [organizationState])

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {roleFetchObjects && (
        <AsyncFetch
          fetchObjects={roleFetchObjects}
          fetchFinished={roleFetchFinished}
        />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='add-new-user-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='add-new-user-dialog'>
          {t('profile.modals.addNewUser.title')}
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent
          className={[
            modalScss.MuiDialogBoxes,
            appScss.flexColumn,
            modalScss.fieldWidths,
            modalScss.addUserModal,
          ].join(' ')}
        >
          <label
            htmlFor='account-new-user'
            className={appScss['input-notched']}
          >
            <span>{t('profile.modals.addNewUser.emailAddress')}</span>
            <textarea
              id='account-new-user'
              onChange={handleEmail}
              placeholder='user1@company.com, user2@company.com'
            />
          </label>

          {roles && !userID && (
            <>
              <span
                className={modalScss['modal-primary-label']}
                style={{ marginTop: '12px' }}
              >
                {t('profile.account.selectRole')}
              </span>
              <button
                type='button'
                className={scss.selectRoleDropdown}
                onClick={() => setRoleDropdown(!showRoleDropdown)}
              >
                {getRoleName()}
                <FontAwesomeIcon
                  icon={showRoleDropdown ? 'chevron-up' : 'chevron-down'}
                  size='1x'
                  pull='right'
                />
              </button>
              {showRoleDropdown && (
                <>
                  <div
                    className={scss.selectRoleDropdownCover}
                    onClick={() => setRoleDropdown(!showRoleDropdown)}
                  />
                  <div
                    className={[
                      scss.dropdownContent,
                      scss.selectRoleDropdownContent,
                    ].join(' ')}
                  >
                    {roles.map(role => (
                      <div
                        key={'role_' + role.id}
                        className={scss.dropdownItem}
                        onClick={() => handleRole(role.slug)}
                      >
                        <div className={scss.dropdownText}>
                          <h4>{role.name}</h4>
                          <p>{role.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}

          <label
            className={modalScss['modal-primary-label']}
            style={{ marginTop: '12px' }}
            htmlFor='account-new-user-account'
          >
            {t('profile.modals.addNewUser.organization')}
            <select
              id='account-new-user-account'
              title='Account'
              className={[scss.selectRole, scss.selectField].join(' ')}
              onChange={handleOrg}
            >
              <option>{t('profile.account.noRoleSelected')}</option>
              {accounts.map(account => (
                <option key={'account_' + account.id} value={account.id}>
                  {account.name}
                </option>
              ))}
            </select>
          </label>

          {organizationState && (
            <label className={modalScss['modal-primary-label']}>
              Map
              {fetching === 'maps' ? (
                <div>
                  <CircularProgress size={34} />
                </div>
              ) : (
                <ReactSelect
                  options={mapOptions}
                  onChange={setSelectedMapValue}
                  defaultValue={selectedMaps}
                  multiple
                  isFixed
                />
              )}
            </label>
          )}
        </DialogContent>

        <DialogActions>
          <button
            type='button'
            className={modalScss.modalMainButton}
            style={{ width: '100%', placeContent: 'center' }}
            onClick={fetching ? null : handleSave}
            disabled={!verifyForm()}
          >
            {fetching ? (
              <CircularProgress size={10} />
            ) : userID == null ? (
              t('profile.modals.addNewUser.button')
            ) : (
              t('common.save')
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function AddNewUserModal(props) {
  return <SimpleDialog {...props} />
}
