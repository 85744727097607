import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as utils from '../../../utilities/util'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'
import { useTranslation } from 'react-i18next'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'
import { MuiButton } from '../../../components/MaterialUi/Dialog'

import scss from '../Accounts.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ userID, firstName, lastName, emailAddress, visible }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [emailAddressState, setEmailAddress] = useState(false)
  const [organizationState, setOrganization] = useState(false)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = (id, val) => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'user/add'
    let body = {
      emailAddress: emailAddressState,
      organization: organizationState,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = () => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })

    setFetchObjects(null)
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  const handleEmail = e => setEmailAddress(e.target.value)
  const handleOrg = e => setOrganization(e.target.value)

  // const getUser = (u) => {
  const getAccounts = () => {
    // var checked = userList.includes(u.id)

    return (
      <div
        style={{ gridTemplateColumns: '60% 40%' }}
        className={modalScss.selectMultipleRow}
      >
        <div>
          <div>
            <span className={modalScss.selectRowMd}>
              {/* {u.displayName} */}
              Jessica Alba
            </span>
          </div>
        </div>
        <div style={{ justifySelf: 'right' }}>
          <select placeholder='Map' className={scss.assignMapSelect}>
            <option value='0'>Map</option>
            <option value='1'>MyAssetMap</option>
            <option value='2'>TAH</option>
          </select>
        </div>
      </div>
    )
  }

  // var usersArea
  // if (!loading && !users.length) {
  //   usersArea = <p>No users found for this account</p>
  // }else{
  //   usersArea = users.map((u) => getUser(u))
  // }

  var accountsArea = getAccounts()

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='assign-account-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='assign-map-dialog'>
          {t('profile.modals.assignMap.title')}
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.MuiDialogBoxes}>
          <div className={modalScss.selectMultipleBox}>
            {loading && (
              <>
                <Skeleton height={59} count={12} />
              </>
            )}

            {accountsArea}
          </div>
        </DialogContent>

        <DialogActions>
          <button className={modalScss.modalAltButton} onClick={handleOnClose}>
            {t('profile.modals.common.cancel')}
          </button>

          <button
            className={modalScss.modalMainButton}
            // disabled={!enableSave}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <CircularProgress size={10} />
            ) : (
              t('profile.modals.assignMap.button')
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function AddNewAccountModal(props) {
  return <SimpleDialog {...props} />
}
