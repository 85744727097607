import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import { PropTypes } from 'prop-types'
import { apis } from '../../../config/apiConfig'

import checkboxScss from '../../CSSModules/Checkbox.scss'
import modalScss from '../../CSSModules/Modal.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { refreshDetails } from '../../../utilities/user'
import AsyncFetch from '../../../utilities/AsyncFetch'
import * as utils from '../../../utilities/util'

function SimpleDialog({ doUpdate, accounts, checkboxList, visible }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  // Fields and their States
  const [userIDState, setUserIDs] = useState([])
  const [checkedAccounts, setCheckedAccounts] = useState([])
  const [userCheckedAccounts, setUserCheckedAccounts] = useState([])

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = () => {
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'account/user/delete'
    const body = {
      userID: userIDState,
      accountID: userCheckedAccounts,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()

      doUpdate()
    })

    setFetchObjects(null)
  }

  // ===================================
  // = PROCESS THE DATA INTO VARIABLES =
  // ===================================

  const processAccounts = checkedUserIDs => {
    let accountIDsByUser = {}
    for (const key in accounts) {
      if (key) {
        const account = accounts[key]
        const { users } = account
        if (!Array.isArray(users)) return

        users.forEach(user => {
          if (checkedUserIDs.includes(user.id)) {
            if (accountIDsByUser[user.id] == null)
              accountIDsByUser[user.id] = []
            accountIDsByUser[user.id] = utils.addVal(
              accountIDsByUser[user.id],
              account.id
            )
          }
        })
      }
    }
    accountIDsByUser = Object.values(accountIDsByUser)
    // console.log(accountIDsByUser)
    if (Array.isArray(accountIDsByUser)) {
      const cAccounts = accountIDsByUser.shift().filter(v => {
        return accountIDsByUser.every(a => {
          return a.indexOf(v) !== -1
        })
      })
      // console.log(cAccounts);
      setCheckedAccounts(cAccounts)
    }
  }

  const processUserIDs = () => {
    let userIDs = []
    for (const key in checkboxList) {
      const check = checkboxList[key]

      const userID = check.user.id

      userIDs = utils.addVal(userIDs, userID)
    }
    setUserIDs(userIDs)

    processAccounts(userIDs)
  }

  useEffect(() => {
    processUserIDs()
  }, [])

  const handleCheckboxClick = accountID => {
    setUserCheckedAccounts(utils.toggleVal(userCheckedAccounts, accountID))
  }

  // const getUser = (u) => {
  const getAccount = account => {
    // var checked = userList.includes(u.id)

    if (!checkedAccounts.includes(account.id)) return null

    return (
      <div className={modalScss.selectMultipleRow}>
        <div>
          <div>
            <label
              htmlFor={'account-checkbox-' + account.id}
              className={[
                checkboxScss.checkboxContainer,
                checkboxScss.altCheckboxContainer,
              ].join(' ')}
            >
              <input
                id={'account-checkbox-' + account.id}
                className={checkboxScss.checkbox}
                type='checkbox'
                checked={userCheckedAccounts.includes(account.id)}
                onClick={() => handleCheckboxClick(account.id)}
              />
              <span
                className={[
                  checkboxScss.checkboxMark,
                  checkboxScss.altCheckboxMark,
                ].join(' ')}
              ></span>
            </label>
          </div>
          <div className={modalScss.selectRowContainer}>
            <span className={modalScss.selectRowSm}>
              {/* {u.displayName} */}
              {account.name}
            </span>
          </div>
        </div>
        <div>
          <span className={modalScss.selectRowTitle}>
            {t('profile.modals.unassign.userCount', {
              count: account.users.length || '-',
              total: account.userCount || '-',
            })}
          </span>
        </div>
      </div>
    )
  }

  let accountsArea
  if (!accounts.length) {
    accountsArea = <p>{t('profile.modals.unassign.noUsers')}</p>
  } else {
    accountsArea = accounts.map(a => getAccount(a))
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='assign-account-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='unassign-account-dialog'>
          {t('profile.modals.unassign.title')}
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.MuiDialogBoxes}>
          <div className={modalScss.selectMultipleBox}>{accountsArea}</div>
        </DialogContent>

        <DialogActions>
          <button
            type='button'
            className={modalScss.modalAltButton}
            onClick={handleOnClose}
          >
            {t('profile.modals.common.cancel')}
          </button>

          <button
            type='button'
            className={modalScss.modalMainButton}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <CircularProgress size={10} />
            ) : (
              t('profile.modals.unassign.button')
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

SimpleDialog.propTypes = {
  doUpdate: PropTypes.func,
  visible: PropTypes.func,
}

export default function UnassignAccountModal(props) {
  return <SimpleDialog {...props} />
}
