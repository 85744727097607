import React, { useState } from 'react'
import { useCheckboxList } from '../../utilities/common/hooks/useCheckboxList'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import * as utils from '../../utilities/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { connect } from 'react-redux'
import { apis } from '../../config/apiConfig'
import { switchPage, Login as _Login, Logout as _Logout } from '../../actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Skeleton from 'react-loading-skeleton'
import Checkbox from 'react-three-state-checkbox'

import Switch from '@material-ui/core/Switch'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import { Tooltip } from 'react-tooltip'

import logoDark from '../../assets/logo/logo-dark.svg'
import organizationLayerGroup from '../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../assets/icons/userLayerGroup.svg'

import ShareModal from '../Tools/ShareModal/ShareModal'
import MapviewDelete from './StoreModals/MapviewDelete'
import dropdownScss from '../CSSModules/Dropdown.scss'
import checkboxScss from '../CSSModules/Checkbox.scss'
import mapviewScss from './MapViewList.scss'
import layerScss from './LayerList.scss'
import scss from './Accounts.scss'

export default function MapViewList({
  mapViewList,
  activeMapViewList,
  setMapViewList,
  checkMapViewFilter,
  handleMapViewStatus,
  isActiveGroup,
  isActiveMapView,
  handleMapViewDelete,
  listArea,
}) {
  const {
    checkboxList,
    checkboxCount,
    toggleCheckbox,
    toggleGroupCheckboxes,
    isChecked,
    getGroupCheckedCount,
  } = useCheckboxList()

  const [collapsed, setCollapsed] = useState([])
  const [showMapViewDropdown, setMapViewDropdown] = useState(false)
  const [mapViewListType, setMapViewListType] = useState(null)

  /* ------------------------------ MODAL STATES ------------------------------ */
  const [shareVisible, setShareVisible] = useState(false)
  const [mapViewDeleteVisible, setMapViewDelete] = useState(false)
  const [shareMapViewVisible, setShareMapView] = useState(false)
  const [visible, setVisible] = useState(false)
  /* -------------------------------------------------------------------------- */

  const user = useSelector(state => state.user)
  const { t } = useTranslation()

  const toggleMapViewShare = mapViewKey => {
    if (shareMapViewVisible == mapViewKey) {
      setShareMapView(false)
    } else setShareMapView(mapViewKey)
  }
  const openShareMapView = shareMapViewVisible =>
    setShareMapView(shareMapViewVisible)

  const toggleMapViewDropdown = mapViewKey => {
    if (showMapViewDropdown == mapViewKey) {
      setMapViewDropdown(false)
    } else setMapViewDropdown(mapViewKey)
  }

  const toggleMapViewDelete = mapViewKey => {
    if (mapViewDeleteVisible == mapViewKey) {
      setMapViewDelete(false)
    } else setMapViewDelete(mapViewKey)
  }

  const openMapviewDelete = mapViewDeleteVisible =>
    setMapViewDelete(mapViewDeleteVisible)

  const handleCollapse = accountID => {
    setCollapsed(utils.toggleVal(collapsed, accountID))
  }

  const getDate = date => {
    if (!date) return '-'
    const convertedDate = new Date(date)
    return new Intl.DateTimeFormat('en-US').format(convertedDate)
  }

  const handleCheckbox = mapView => {
    toggleCheckbox(mapView.id, { mapView })
  }

  const handleGroupCheckbox = (e, group) => {
    toggleGroupCheckboxes(group.id, group.children || [], e.target.checked)
  }

  const getLayerCheckedCount = layerId => {
    return getGroupCheckedCount(layerId)
  }

  const displayMapView = mapView => {
    if (!checkMapViewFilter(mapView, mapView.children)) return

    resultsFound = true

    const curLayerCount = getLayerCheckedCount(mapView.id)
    const totalCount = mapView.children ? mapView.children.length : 1

    const checkedValue = curLayerCount === totalCount
    const indeterminateValue = curLayerCount > 0 && curLayerCount < totalCount

    const makeLayerButton = (mapView, isChild = false) => {
      if (!checkMapViewFilter(mapView, mapView.children)) return

      if (mapView && mapView.children) {
        //IS A GROUP
        return getTocGroup(mapView, isChild)
      } else {
        //IS A LAYER
        return getTocMapview(mapView)
      }
    }

    const canEdit = mapView.canEdit || user.isAdmin
    const canDelete = mapView.canDelete || user.isAdmin

    const getTocGroup = (group, isChild) => {
      if (!group) return null

      if (!group.children) return null

      var groupShare, groupClass
      groupShare = ''
      groupClass = scss.filterShare

      if (group.type === 'user') {
        groupShare = userLayerGroup
        groupClass = mapviewScss.layerBoxTypeImage
      } else if (group.type === 'shared') {
        groupShare = sharedLayerGroup
      } else if (group.type === 'org') {
        groupShare = organizationLayerGroup
      } else {
        groupShare = logoDark
        groupClass = mapviewScss.layerBoxTypeOrgImage
      }

      return (
        <div
          className={
            group.children.length !== 0 && isChild == false
              ? layerScss.groupBox
              : ''
          }
        >
          <div
            className={[
              scss.layerBoxRowHeading,
              layerScss.layerRowHeadings,
              layerScss.groupRow,
            ].join(' ')}
          >
            <label
              className={[
                checkboxScss.checkboxContainer,
                checkboxScss.altCheckboxContainer,
              ].join(' ')}
            >
              <Checkbox
                className={checkboxScss.inputIndeterminate}
                checked={checkedValue}
                indeterminate={indeterminateValue}
                onChange={e => handleGroupCheckbox(e, group)}
              />
              <span
                className={[
                  checkboxScss.checkboxMark,
                  checkboxScss.altCheckboxMark,
                ].join(' ')}
              ></span>
            </label>

            <div className={layerScss.emptyGrid}></div>

            <div className={scss.adminBoxRowItems}>
              <span
                className={scss.adminBoxText}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img src={groupShare} className={groupClass}></img>
                <div className={layerScss.layerNameBox}>
                  <span className={layerScss.groupLabel}>
                    {t('mapView.group.label', { name: group.label })}
                  </span>
                  <p className={layerScss.layerID}>
                    {t('mapView.group.id', { id: group.id })}
                  </p>
                </div>
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                <Switch
                  checked={isActiveGroup(group)}
                  onChange={() => handleMapViewStatus(group)}
                  name='groupStatus'
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                />
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            {canEdit || canDelete ? (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center', overflow: 'visible' }}
                onClick={() =>
                  toggleMapViewDropdown(user.id + '_' + mapView.id)
                }
              >
                {/* ----------------------------- ASSIGN DROPDOWN ---------------------------- */}
                <div
                  className={[
                    dropdownScss.dropdown,
                    scss.adminUserOptions,
                  ].join(' ')}
                >
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-h']}
                    style={{ fontSize: '16px' }}
                  />
                  {showMapViewDropdown == user.id + '_' + mapView.id && (
                    <>
                      <div
                        className={dropdownScss.dropdownCover}
                        onClick={() =>
                          toggleMapViewDropdown(user.id + '_' + mapView.id)
                        }
                      />
                      <div
                        className={[
                          dropdownScss.dropdownContent,
                          dropdownScss.assignDropdownContent,
                        ].join(' ')}
                        style={{ position: 'absolute', right: '0' }}
                      >
                        {canDelete && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div
                              onClick={() =>
                                toggleMapViewDelete(user.id + '_' + mapView.id)
                              }
                            >
                              <span>{t('mapView.actions.delete')}</span>
                            </div>
                          </div>
                        )}
                        {canEdit && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div
                              onClick={
                                canEdit
                                  ? () => toggleMapViewShare(mapView.id)
                                  : null
                              }
                            >
                              <span>{t('mapView.actions.share')}</span>
                            </div>
                          </div>
                        )}
                        {canEdit && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div
                            // onClick={() => toggleMapViewDelete(user.id+'_'+mapView.id)}
                            >
                              <span>{t('mapView.actions.edit')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* -------------------------------------------------------------------------- */}
              </div>
            ) : (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center' }}
              >
                <span className={scss.adminUserOptions}>
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-h']}
                    style={{ color: '#a0a5ac', fontSize: '16px' }}
                  />
                </span>
              </div>
            )}

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
              onClick={() => handleCollapse(group.id)}
            >
              {group.children.length != 0 && (
                <span className={scss.adminBoxText}>
                  <FontAwesomeIcon
                    icon={
                      collapsed.includes(group.id)
                        ? 'chevron-down'
                        : 'chevron-up'
                    }
                    style={{ fontSize: '16px' }}
                  />
                </span>
              )}
            </div>
          </div>

          <div className={layerScss.emptyGrid}></div>

          <Collapse
            className={mapviewScss.muiPaperCollapse}
            in={!collapsed.includes(group.id)}
          >
            <Paper
              className={[scss.muiPaperShadow, mapviewScss.muiPaperShadow].join(
                ' '
              )}
              elevation={4}
            >
              {
                (listArea = group.children.map(mapView =>
                  makeLayerButton(mapView, true)
                ))
              }
            </Paper>
          </Collapse>

          {mapViewDeleteVisible == user.id + '_' + mapView.id && (
            <MapviewDelete
              onClose={() => openMapviewDelete(false)}
              mapViewLabel={mapView.label}
              mapViewID={mapView.id}
              visible={visible}
              setVisible={setVisible}
              mapViewList={mapViewList}
              setMapViewList={setMapViewList}
            />
          )}
          {shareMapViewVisible == mapView.id && (
            <ShareModal
              onClose={() => openShareMapView(false)}
              itemId={mapView.id}
              dataType={'mapViewGroup'}
            />
          )}
        </div>
      )
    }

    const getTocMapview = mapView => {
      var layerShare, layerClass
      layerShare = ''
      layerClass = scss.filterShare

      if (mapView.type === 'user') {
        layerShare = userLayerGroup
        layerClass = layerScss.layerBoxTypeImage
      } else if (mapView.type === 'shared') {
        layerShare = sharedLayerGroup
      } else if (mapView.type === 'org') {
        layerShare = organizationLayerGroup
      } else {
        layerShare = logoDark
        layerClass = layerScss.layerBoxTypeOrgImage
      }

      return (
        <>
          <div
            className={[
              scss.layerBoxRowHeading,
              layerScss.layerRowHeadings,
            ].join(' ')}
          >
            <label className={checkboxScss.checkboxContainer}>
              <input
                className={checkboxScss.checkbox}
                type='checkbox'
                checked={isChecked(mapView.id)}
                onChange={() => handleCheckbox(mapView)}
              />
              <span className={checkboxScss.checkboxMark}></span>
            </label>

            <div className={layerScss.emptyGrid}></div>

            <div className={scss.adminBoxRowItems}>
              <span
                className={scss.adminBoxText}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img src={layerShare} className={layerClass}></img>
                <div className={layerScss.layerNameBox}>
                  {mapView.label}
                  <p className={layerScss.layerID}>
                    {t('mapView.layer.id', { id: mapView.id })}
                  </p>
                </div>
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              <span className={scss.adminBoxText}>
                <div className={mapviewScss.mapviewNameBox}>
                  <p className={mapviewScss.mapviewDescription}>
                    {mapView.description !== ''
                      ? mapView.description
                      : t('mapView.layer.description.empty')}
                  </p>
                  <span className={mapviewScss.mapviewAuthor}>
                    {t('mapView.layer.ownership', { owner: mapView.owner })}{' '}
                    &nbsp;|&nbsp; {t('mapView.layer.lastEdited')}
                  </span>
                </div>
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div className={scss.adminBoxRowItems}>
              <span className={scss.adminBoxText}>
                <p className={layerScss.layerDate}>
                  {getDate(mapView.dateUpdated)}
                </p>
                <p className={layerScss.layerDate}>
                  {getDate(mapView.dateCreated)}
                </p>
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                <Switch
                  checked={isActiveMapView(mapView)}
                  onChange={() => handleMapViewStatus(mapView)}
                  name='mapviewStatus'
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                />
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            {canEdit || canDelete ? (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center', overflow: 'visible' }}
                onClick={() =>
                  toggleMapViewDropdown(user.id + '_' + mapView.id)
                }
              >
                {/* ----------------------------- ASSIGN DROPDOWN ---------------------------- */}
                <div
                  className={[
                    dropdownScss.dropdown,
                    scss.adminUserOptions,
                  ].join(' ')}
                >
                  <FontAwesomeIcon icon={['far', 'ellipsis-h']} size='1x' />
                  {showMapViewDropdown == user.id + '_' + mapView.id && (
                    <>
                      <div
                        className={dropdownScss.dropdownCover}
                        onClick={() =>
                          toggleMapViewDropdown(user.id + '_' + mapView.id)
                        }
                      />
                      <div
                        className={[
                          dropdownScss.dropdownContent,
                          dropdownScss.assignDropdownContent,
                        ].join(' ')}
                        style={{ position: 'absolute', right: '0' }}
                      >
                        {canDelete && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div
                              onClick={() =>
                                toggleMapViewDelete(user.id + '_' + mapView.id)
                              }
                            >
                              <span>{t('mapView.actions.delete')}</span>
                            </div>
                          </div>
                        )}
                        {canEdit && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div
                              onClick={
                                canEdit
                                  ? () => toggleMapViewShare(mapView.id)
                                  : null
                              }
                            >
                              <span>{t('mapView.actions.share')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* -------------------------------------------------------------------------- */}
              </div>
            ) : (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center', overflow: 'visible' }}
              >
                <div className={[scss.adminUserOptions].join(' ')}>
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-h']}
                    style={{ color: '#a0a5ac', fontSize: '16px' }}
                  />
                </div>
              </div>
            )}

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span
                className={[scss.adminBoxText, mapviewScss.layerBoxType].join(
                  ' '
                )}
              >
                &nbsp;
              </span>
            </div>
          </div>

          {mapViewDeleteVisible == user.id + '_' + mapView.id && (
            <MapviewDelete
              onClose={() => openMapviewDelete(false)}
              mapViewLabel={mapView.label}
              mapViewID={mapView.id}
              visible={visible}
              setVisible={setVisible}
              mapViewList={mapViewList}
              setMapViewList={setMapViewList}
            />
          )}
          {shareMapViewVisible == mapView.id && (
            <ShareModal
              onClose={() => openShareMapView(false)}
              itemId={mapView.id}
              dataType={'mapView'}
            />
          )}
        </>
      )
    }

    return makeLayerButton(mapView)
  }

  var resultsFound = false

  if (!mapViewList.length) {
    listArea = (
      <div className={mapviewScss.noResults}>
        <FontAwesomeIcon icon={['fal', 'search']} />
        <FontAwesomeIcon icon={'question'} />
        <span className={mapviewScss.noResultsHeading}>No Results Found</span>
        <span className={mapviewScss.noResultsParagraph}>
          Try adjusting your search to find what you’re looking for
        </span>
      </div>
    )
  } else {
    listArea = mapViewList.map(mapView => displayMapView(mapView))
    if (!resultsFound) {
      listArea = (
        <div className={mapviewScss.noResults}>
          <FontAwesomeIcon icon={['fal', 'search']} />
          <FontAwesomeIcon icon={'question'} />
          <span className={mapviewScss.noResultsHeading}>No Results Found</span>
          <span className={mapviewScss.noResultsParagraph}>
            Try adjusting your search to find what you’re looking for
          </span>
        </div>
      )
    }
  }

  return (
    <div className={[scss.layerBox, layerScss.layerContainer].join(' ')}>
      {listArea}
    </div>
  )
}
