import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refreshDetails } from '../../utilities/user'

import * as utils from '../../utilities/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { connect } from 'react-redux'
import AsyncFetch from '../../utilities/AsyncFetch'
import { apis } from '../../config/apiConfig'
import { switchPage, Login as _Login, Logout as _Logout } from '../../actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Skeleton from 'react-loading-skeleton'
import Checkbox from 'react-three-state-checkbox'
import { updateDataConfig } from '../../actions'
import MakeDataConfigComponent from '../../utilities/dataConfig'

import checkboxScss from '../CSSModules/Checkbox.scss'
import appScss from '../App/App.scss'
import profileScss from './Profile.scss'
import scss from './Accounts.scss'
import mapViewScss from './MapViewList.scss'
import layerListScss from './LayerList.scss'
import layerScss from './Layers.scss'

import LayerDelete from './StoreModals/LayerDelete'
import MapViewList from './MapViewList'

import ActivateUserModal from './AccountModals/activateUser'
import DeactivateUserModal from './AccountModals/deactivateUser'
import { mapView } from '@fortawesome/fontawesome-svg-core'
import sharedLayerGroup from '../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../assets/icons/userLayerGroup.svg'
import organizationLayerGroup from '../../assets/icons/organizationLayerGroup.svg'
import logoDark from '../../assets/logo/logo-dark.svg'
import dropdownScss from '../CSSModules/Dropdown.scss'
import { useTranslation } from 'react-i18next'

export default function MapViews() {
  const { t } = useTranslation()
  // const dispatch = useDispatch();
  const user = useSelector(state => state.user)

  let account = utils.objectKeyVal(user.accountID, user.accounts, 'id')
  // console.warn("THIS IS THE LAYER",mapView)
  const mapList = account.maps
  const filterLayerList = account.mapView
  const map = utils.objectKeyVal(user.mapID, mapList, 'id')
  const typeList = [
    ['My MapViews', 'user', userLayerGroup, layerScss.layerBoxTypeImage],
    ['Shared with User', 'Shared', sharedLayerGroup, layerScss.layerTypeIcon],
    [
      'Shared with Organization',
      'Org',
      organizationLayerGroup,
      layerScss.layerTypeIcon,
    ],
  ]

  const [showDropdown, setDropdown] = useState(false)
  const [users, setUsers] = useState([])
  const [mapViewList, setMapViewList] = useState([])
  const [activeMapViewList, setActiveMapviewList] = useState([])
  const [update, doUpdate] = useState([true])

  const [checkboxList, setCheckboxList] = useState({})
  const [checkboxCount, setCheckboxCount] = useState(0)

  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(null)

  /* ------------------------------ FILTER STATES ------------------------------ */
  const [filterSearch, setFilterSearch] = useState('')
  const [filterType, setFilterType] = useState('')
  const [filterMap, setFilterMap] = useState('')
  /* --------------------------------------------------------------------------- */

  /* ----------------------------- DROPDOWN STATES ---------------------------- */
  const [showAssignDropdown, setAssignDropdown] = useState(false)
  const [showMapViewTypeDropdown, setShowMapViewTypeDropdown] = useState(false)
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ DATA STATES ------------------------------ */
  const [availableLoading, setAvailableLoading] = useState(false)
  const [activeLoading, setActiveLoading] = useState(false)
  const [availableFetchObjects, setAvailableFetchObjects] = useState(null)
  const [activeFetchObjects, setActiveFetchObjects] = useState(null)
  const [activateDeactivateObjects, setActivateDeactivateObjects] =
    useState(null)
  const [mapUpdateObjects, setMapUpdateObjects] = useState(null)
  const [fetching, setFetching] = useState(false)

  const [removeList, setRemoveList] = useState([])
  const [addList, setAddList] = useState([])

  const [sortBy, setSortBy] = useState({
    type: 'createdDate',
    direction: 'ascending',
  })

  const dispatch = useDispatch()

  const adminBoxStatusIconRed = { color: '#ff0000' } // RED COLOR
  const adminBoxStatusIcon = { color: '#44df60' } // GREEN COLOR

  var listArea

  useEffect(() => {
    updateLayerList()
  }, [update])

  // ====================================
  // =  Fetch Layer List from Database  =
  // ====================================

  const dataConfigUpdated = dataConfig => dispatch(updateDataConfig(dataConfig))

  const buildFetchParams = (id, e) => {
    if (id === 'available') {
      const method = 'GET'
      const url = apis['apiDatabase'].uri + 'mapviews/available'
      const body = {
        mapID: user.mapID,
      }

      setAvailableLoading(true)
      setAvailableFetchObjects([{ url, method, body }])
    } else if (id === 'active') {
      const method = 'GET'
      const url = apis['apiDatabase'].uri + 'mapviews/active'
      const body = {
        mapID: user.mapID,
      }

      setActiveLoading(true)
      setActiveFetchObjects([{ url, method, body }])
    } else if (id === 'update') {
      var newMapID = e.target.value
      if (newMapID == '') resetMapviews()

      console.log('Update Map:', newMapID)
      resetMapviews()
      const method = 'POST'
      const url = apis['apiDatabase'].uri + 'user/update'
      const body = {
        mapID: newMapID,
      }

      setMapUpdateObjects([{ url, method, body }])
    }
  }

  const updateLayerList = () => {
    //Reset
    setCheckboxList({})
    setCheckboxCount(0)

    //Continue
    console.log('Updating Layers')
    setAvailableLoading(true)
    setActiveLoading(true)
    buildFetchParams('active')
    buildFetchParams('available')
  }

  // =====================
  // = DROPDOWN HANDLING =
  // =====================
  const resetDropdowns = () => {
    setAssignDropdown(false)
  }

  const pageClicked = () => {
    resetDropdowns()
  }

  const toggleAssignSwitcher = () => {
    resetDropdowns()
    if (showAssignDropdown == true) {
      setAssignDropdown(false)
    } else setAssignDropdown(true)
  }

  const toggleMapViewTypeDropdown = () => {
    setShowMapViewTypeDropdown(showMapViewTypeDropdown ? false : true)
  }

  const isActiveMapView = mapView => {
    if (activeMapViewList) {
      return activeMapViewList.includes(mapView.id)
    }
  }

  const isActiveGroup = mapView => {
    return isActiveMapView(mapView)

    if (!mapView.children || !Array.isArray(mapView.children))
      return isActiveMapView(mapView)
    var active = true
    mapView.children.every(groupLayer => {
      if (!isActiveGroup(groupLayer)) {
        active = false
        return false
      }
    })
    return active
  }

  // =====================
  // = CHECKBOX HANDLING =
  // =====================
  const getCheckbox = mapView => {
    return mapView.id in checkboxList
  }

  const clickCheckbox = mapView => {
    var newVal = utils.toggleVal(checkboxList, mapView.id, { mapView })
    // console.log('Checkbox Clicked',newVal,Object.keys(newVal).length)
    setCheckboxList(newVal)
    setCheckboxCount(Object.keys(newVal).length)
  }

  const getCountChecked = accID => {
    if (accID == null) return checkboxCount

    var count = 0
    for (const checkKey in checkboxList) {
      var checkArr = checkKey.split('_')
      if (checkArr.length >= 2) {
        var thisAccountID = checkArr[0]
        // var thisUserID = checkArr[1];

        if (thisAccountID == accID) count++
      }
    }
    return count
  }

  const getTotalCount = () => {
    var count = 0
    mapViewList.forEach(function (acc) {
      if (acc.users && Array.isArray(acc.users)) {
        count += acc.users.length
      }
    })
    return count
  }

  const toggleMapViewChecks = (e, layr) => {
    mapViewList.forEach(function (usr) {
      if (e.target.checked) {
        var newVal = utils.addVal(checkboxList, layr.id + '_' + usr.id, {
          mapView: layr,
          user: usr,
        })
      } else {
        var newVal = utils.unVal(checkboxList, layr.id + '_' + usr.id)
      }
      // console.log('Account Checkbox Clicked',newVal,e.target.checked)
      setCheckboxList(newVal)
      setCheckboxCount(Object.keys(newVal).length)
    })
  }

  const togglePageChecks = e => {
    mapViewList.forEach(function (mapView) {
      mapView.children.forEach(function (usr) {
        if (e.target.checked) {
          var newVal = utils.addVal(checkboxList, mapView.id + '_' + usr.id, {
            mapView: mapView,
            user: usr,
          })
        } else {
          var newVal = utils.unVal(checkboxList, mapView.id + '_' + usr.id)
        }
        // console.log('Account Checkbox Clicked',newVal,e.target.checked)
        setCheckboxList(newVal)
        setCheckboxCount(Object.keys(newVal).length)
      })
    })
  }

  // =================================
  // =================================
  // =================================
  // ============ FILTERS ============
  // =================================
  // =================================
  // =================================
  const checkMapViewFilter = (mapView, layerChildren) => {
    //Check Search
    if (filterType !== '') {
      var passedChecks_type = false
      var type = filterType[1].toLowerCase()

      //Check Type
      if (mapView && mapView.type == type) passedChecks_type = true

      if (!passedChecks_type) return false
    }

    if (filterSearch !== '') {
      var passedChecks_search = false
      var search = filterSearch.toLowerCase()

      //Active Check
      if (search === 'active') {
        if (mapView && isActiveMapView(mapView)) passedChecks_search = true
      } else if (search === 'inactive') {
        if (mapView && !isActiveMapView(mapView)) passedChecks_search = true
      } else {
        //Check Label
        if (mapView && mapView.id == search) passedChecks_search = true
        if (
          mapView &&
          mapView.label &&
          mapView.label.toLowerCase().indexOf(search) !== -1
        )
          passedChecks_search = true
        if (
          mapView &&
          mapView.description &&
          mapView.description.toLowerCase().indexOf(search) !== -1
        )
          passedChecks_search = true
        if (
          mapView &&
          mapView.owner &&
          mapView.owner.toLowerCase().indexOf(search) !== -1
        )
          passedChecks_search = true
      }

      //Check Children
      if (layerChildren && Array.isArray(layerChildren)) {
        layerChildren.every(groupLayer => {
          if (checkMapViewFilter(groupLayer, groupLayer.children)) {
            passedChecks_search = true
            return false
          }
        })
      }

      if (!passedChecks_search) {
        listArea = (
          <div className={mapViewScss.noResults}>
            <FontAwesomeIcon icon={['fal', 'search']} />
            <FontAwesomeIcon icon={'question'} />
            <span className={mapViewScss.noResultsHeading}>
              {t('mapViews.noResults.title')}
            </span>
            <span className={mapViewScss.noResultsParagraph}>
              {t('mapViews.noResults.description')}
            </span>
          </div>
        )

        return false
      }
    }

    return true
  }

  const resetMapviews = () => {
    setMapViewList([])
    setActiveMapviewList([])

    setAddList([])
    setRemoveList([])
    // setAvailableLoading(true); // <-- I think these are right here?
    // setActiveLoading(true);
    setFetching(false)
  }

  const updateMap = e => {
    buildFetchParams('update', e)
  }

  // ================================
  // = HANDLING THE CHECKBOX STATUS =
  // ================================
  var checkedValue
  var indeterminateValue
  var curAccountCount = getCountChecked()
  if (curAccountCount == 0) {
    checkedValue = false
    indeterminateValue = false
  } else if (curAccountCount == getTotalCount()) {
    checkedValue = true
    indeterminateValue = false
  } else {
    checkedValue = false
    indeterminateValue = true
  }

  // ====================================
  // = ACTIVE/DEACTIVATE/DELETE STYLING =
  // ====================================

  // Show the add Account BTN if a super admin.
  var showAddAccountBtn = false
  if (utils.isSuperAdmin(user)) showAddAccountBtn = true

  const handleMapViewStatus = (mapView, action = true) => {
    const getGroupChildrenIds = view => {
      let ids = []

      const processMapView = childView => {
        childView.children.forEach(child => {
          ids.push(child.id)

          if (child.children && child.children.length) {
            processMapView(child)
          } else return
        })
      }

      processMapView(view)
      return ids
    }

    if (mapView == null) {
      // Triggered by checkbox usecase

      var activateMapviewKeyList = Object.keys(checkboxList)
        .map(Number)
        .filter(mapView => activeMapViewList.indexOf(mapView) === -1)
      var deactivateMapviewKeyList = Object.keys(checkboxList)
        .map(Number)
        .filter(mapView => activeMapViewList.indexOf(mapView) !== -1)

      if (action && activateMapviewKeyList.length > 0) {
        let newActiveMapviewList = activeMapViewList
        activateMapviewKeyList.forEach(mapviewID => {
          newActiveMapviewList = utils.addVal(newActiveMapviewList, mapviewID)
        })
        console.log('KEY LIST: ', newActiveMapviewList, activateMapviewKeyList)
        setAddList(activateMapviewKeyList)
        setActiveMapviewList(newActiveMapviewList)

        // buildRemoveParams(mapView,'delete');
      } else if (!action && deactivateMapviewKeyList.length > 0) {
        let newActiveMapviewList = activeMapViewList
        deactivateMapviewKeyList.forEach(mapviewID => {
          newActiveMapviewList = utils.unVal(newActiveMapviewList, mapviewID)
        })
        setRemoveList(deactivateMapviewKeyList)
        setActiveMapviewList(newActiveMapviewList)
      }
      // buildRemoveParams(mapView,'add');
      return
    } else {
      // triggered by switch usecase

      if (activeMapViewList.indexOf(mapView.id) !== -1) {
        // mapview is active, deactivate
        if (mapView.children && mapView.children.length) {
          // group
          const childrenIds = getGroupChildrenIds(mapView)
          childrenIds.push(mapView.id)

          let finalArray = activeMapViewList
          childrenIds.forEach(id => {
            finalArray = utils.unVal(finalArray, id)
          })
          setRemoveList(childrenIds)
          setActiveMapviewList(finalArray)
        } else {
          // indiv mapview
          setRemoveList([mapView.id])
          setActiveMapviewList(utils.unVal(activeMapViewList, mapView.id))
        }
      } else {
        // mapview is inactive, activate
        if (mapView.children && mapView.children.length) {
          // group
          const childrenIds = getGroupChildrenIds(mapView)
          childrenIds.push(mapView.id)

          let finalArray = activeMapViewList
          childrenIds.forEach(id => {
            finalArray = utils.addVal(finalArray, id)
          })

          let aList = childrenIds.filter(childId => {
            if (activeMapViewList.indexOf(childId) === -1) return true
          })

          setAddList(aList)
          setActiveMapviewList(finalArray)
        } else {
          // indiv mapview
          setAddList([mapView.id])
          setActiveMapviewList(utils.addVal(activeMapViewList, mapView.id))
        }
      }
    }
  }

  const buildActivateDeactivateParams = id => {
    let method = 'POST'
    let url
    let body

    if (id === 'delete') {
      url = apis['apiDatabase'].uri + 'mapviews/user/delete'
      body = {
        mapViewID: removeList,
      }
    } else if (id === 'add') {
      url = apis['apiDatabase'].uri + 'mapviews/user/add'
      body = {
        mapViewID: addList,
      }
    }

    console.log('params', url, method, body)
    setActivateDeactivateObjects([{ url, method, body }])
  }

  // Fetch Cleanups

  const availableFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        console.log(' availableLayersAPI result ', result)
        // console.log('1',availableLayersAPI,'2',utils.resultReturn(result));
        setMapViewList(utils.resultReturn(result))
      }

      setAvailableLoading(false)
      setAvailableFetchObjects(null)
    })
  }

  const activeFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        // console.log('1',availableLayersAPI,'2',utils.resultReturn(result));
        setActiveMapviewList(utils.resultReturn(result))
      }

      setActiveLoading(false)
      setActiveFetchObjects(null)
    })
  }

  const activateDeactivateFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        const newLayerList = res.data.activeMapViews
        setActiveMapviewList(newLayerList)
      }
    })

    setAddList([])
    setRemoveList([])
    if (activateDeactivateObjects) setActivateDeactivateObjects(null)
    setFetching(false)
    setMountMakeDataConfig(true)

    // updateActiveLayerPanel()
  }

  const mapUpdateFinished = () => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
    })

    setMapUpdateObjects(null)
  }

  // handler functions
  const handleSortByChange = e => {
    setSortBy({ ...sortBy, type: e.target.value })
  }

  const handleSortList = sortBy => {
    // console.log('layerList', layerList)
    let copy = mapViewList.slice()

    const { type, direction } = sortBy
    let valA, valB

    // helpers
    const compare = (a, b) => {
      if (type === 'name') {
        valA = a.label.toUpperCase()
        valB = b.label.toUpperCase()
      } else if (type === 'createdDate') {
        valA = a.dateCreated
        valB = b.dateCreated
      } else if (type === 'updatedDate') {
        valA = a.dateUpdated
        valB = b.dateUpdated
      }

      let comparison = 0
      if (valA > valB) {
        comparison = 1
      } else if (valB > valA) {
        comparison = -1
      }

      return comparison
    }

    // main functionality

    if (type === 'status') {
      let activeList = copy.filter(obj => {
        if (isActiveGroup(obj) || isActiveMapView(obj)) return true
      })

      const inactiveList = copy.filter(obj => {
        if (!isActiveGroup(obj) && !isActiveMapView(obj)) return true
      })

      const newMapViewList = [...activeList, ...inactiveList]

      if (direction === 'descending') newMapViewList.reverse()
      setMapViewList(newMapViewList)
    } else {
      copy.sort(compare)
      if (direction === 'descending') copy.reverse()
      setMapViewList(copy)
    }
  }

  const toggleDirection = () => {
    if (sortBy.direction === 'ascending')
      setSortBy({ ...sortBy, direction: 'descending' })
    if (sortBy.direction === 'descending')
      setSortBy({ ...sortBy, direction: 'ascending' })
  }

  const handleSortByClick = type => {
    if (type == sortBy.type) {
      toggleDirection()
    } else {
      setSortBy({ ...sortBy, type: type })
    }
  }

  // fetch calls

  const updateAvailableLayerPanel = () => {
    buildFetchParams('available')
  }

  const updateActiveLayerPanel = () => {
    buildFetchParams('active')
  }

  useEffect(() => {
    console.log(removeList)
    if (!removeList || (removeList && !removeList.length) || removeList === [])
      return

    console.log('updating removelist')
    console.log('removeList')
    buildActivateDeactivateParams('delete')
  }, [removeList])

  useEffect(() => {
    console.log(addList)
    if (!addList || (addList && !addList.length) || addList === []) return

    console.log('updating addlist')
    buildActivateDeactivateParams('add')
  }, [addList])

  useEffect(() => {
    updateAvailableLayerPanel()
    updateActiveLayerPanel()
  }, [user])

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(() => {
    console.log('SORT BY', sortBy)
    handleSortList(sortBy)
  }, [sortBy])

  // const sortTypes = ['Created Date', 'Updated Date', 'Name', 'Type', 'Status']
  const sortTypes = [
    { name: 'Created Date', value: 'createdDate' },
    { name: 'Updated Date', value: 'updatedDate' },
    { name: 'Name', value: 'name' },
    { name: 'Status', value: 'status' },
  ]

  let sortUpIcon = ['fa', 'sort-up']
  let sortDownIcon = ['fa', 'sort-down']

  return (
    <>
      {mountMakeDataConfig && (
        <MakeDataConfigComponent
          onFinish={dataConfigUpdated}
          updateStyle={false}
        />
      )}
      {
        // Available Layers Fetch on state change
        availableFetchObjects && (
          <AsyncFetch
            fetchObjects={availableFetchObjects}
            fetchFinished={availableFinished}
          />
        )
      }
      {
        // Active Layers Fetch on state change
        activeFetchObjects && (
          <AsyncFetch
            fetchObjects={activeFetchObjects}
            fetchFinished={activeFinished}
          />
        )
      }
      {
        // Api call for updating map
        mapUpdateObjects && (
          <AsyncFetch
            fetchObjects={mapUpdateObjects}
            fetchFinished={mapUpdateFinished}
          />
        )
      }
      {
        // Api call for removals
        activateDeactivateObjects && (
          <AsyncFetch
            fetchObjects={activateDeactivateObjects}
            fetchFinished={activateDeactivateFinished}
          />
        )
      }
      <div
        //  onClick={pageClicked}
        className={scss.usersContainer}
      >
        <div className={scss.adminHeaderLabel}>
          <FontAwesomeIcon
            style={{ marginRight: '7px' }}
            icon={['far', 'filter']}
            size='1x'
          />
          <span style={{ color: '#000' }}>Filters:</span>
        </div>

        {/* ADMIN HEADER WITH FILTERS AND BUTTONS */}
        <div className={scss.adminHeader}>
          <div className={scss.adminFilters}>
            <input
              style={{
                maxWidth: '236px',
                height: '36px',
                fontStyle: 'italic',
                textAlign: 'left',
              }}
              className={appScss.mainInput}
              type='search'
              placeholder={t('mapViews.filters.search')}
              onChange={e => setFilterSearch(e.target.value)}
            />

            {/* <select style={{minWidth: "204px", height: "36px", fontStyle: "italic"}} className={appScss.altSelect} onChange={e => setFilterType(e.target.value)}>
              <option value={''}>All Layers</option>
              {typeList.map((type) => (<option style={{textTransform: "capitalize"}} value={type[1]}>{type[0]}</option>))}
            </select> */}

            <div
              className={[
                layerListScss.layerDropdownSelect,
                dropdownScss.dropdown,
                scss.adminUserOptions,
              ].join(' ')}
              onClick={() => toggleMapViewTypeDropdown()}
            >
              <div>
                <img
                  src={filterType === '' ? '' : filterType[2]}
                  className={filterType === '' ? '' : filterType[3]}
                ></img>
                {filterType === '' ? 'All Map Views' : filterType[0]}
              </div>
              <FontAwesomeIcon
                icon={[
                  'fas',
                  showMapViewTypeDropdown ? 'chevron-up' : 'chevron-down',
                ]}
                size='1x'
              />
              {showMapViewTypeDropdown ? (
                <>
                  <div
                    className={dropdownScss.dropdownCover}
                    onClick={() => toggleMapViewTypeDropdown()}
                  />
                  <div
                    className={[
                      dropdownScss.dropdownContent,
                      dropdownScss.assignDropdownContent,
                    ].join(' ')}
                    style={{ position: 'absolute', left: '0', top: '36px' }}
                  >
                    <div
                      className={[dropdownScss.assignDropdownItem].join(' ')}
                      onClick={() => setFilterType('')}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '11% 89%',
                        }}
                      >
                        <div></div>
                        <span>All Map Views</span>
                      </div>
                    </div>
                    {typeList &&
                      typeList.map(type => (
                        <div
                          /* value={type[1]} */ className={[
                            dropdownScss.assignDropdownItem,
                          ].join(' ')}
                          onClick={() => setFilterType(type)}
                        >
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '11% 89%',
                            }}
                          >
                            <img src={type[2]} className={type[3]}></img>
                            <span>{type[0]}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ) : null}
            </div>

            <select
              style={{ minWidth: '204px', height: '36px', fontStyle: 'italic' }}
              className={appScss.altSelect}
              onChange={handleSortByChange}
            >
              {sortTypes.map(type => (
                <option
                  style={{ textTransform: 'capitalize' }}
                  value={type.value}
                >
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          <div
            className={[
              scss.adminButtons,
              Object.keys(checkboxList).length
                ? ''
                : scss.adminButtons_disabled,
            ].join(' ')}
          >
            {/* <a
              className={scss.accountHeaderButtons}
              // onClick={() => checkboxCount > 0  && handleMapViewStatus(null,true)}
            >
              <span>Share</span>
              <FontAwesomeIcon icon="share-alt" size="1x" />
            </a> */}

            <a
              className={scss.accountHeaderButtons}
              // onClick={() => checkboxCount > 0 && setActivateLayerVisible(true)}
              onClick={() =>
                checkboxCount > 0 && handleMapViewStatus(null, true)
              }
            >
              <span>Activate</span>
              <FontAwesomeIcon
                style={adminBoxStatusIcon}
                icon='circle'
                size='1x'
              />
            </a>

            <a
              className={scss.accountHeaderButtons}
              // onClick={() => checkboxCount > 0 && setDeactivateLayerVisible(true)}
              onClick={() =>
                checkboxCount > 0 && handleMapViewStatus(null, false)
              }
            >
              <span>Deactivate</span>
              <FontAwesomeIcon
                style={adminBoxStatusIconRed}
                icon='circle'
                size='1x'
              />
            </a>
          </div>
        </div>

        {/* ADMIN HEADER WITH TABLE HEADINGS */}
        <div
          className={[scss.layerHeaderBox, layerListScss.layerHeaderGrid].join(
            ' '
          )}
        >
          {/* ALT CUSTOM CHECKBOX BELOW */}
          <label
            className={[
              checkboxScss.checkboxContainer,
              checkboxScss.altCheckboxContainer,
            ].join(' ')}
            style={{ marginBottom: '8px' }}
          >
            {/* <Checkbox className={checkboxScss.inputIndeterminate} 
              checked={checkedValue}
              indeterminate={indeterminateValue}
              onChange={(e) => togglePageChecks(e)}
            />
            <span className={[checkboxScss.checkboxMark, checkboxScss.altCheckboxMark].join(" ")}></span> */}
          </label>

          <div className={layerListScss.emptyGrid}></div>

          <div className={scss.adminBoxRowItems}>
            <h4
              className={[scss.adminHeadings, scss.adminCursor].join(' ')}
              onClick={() => handleSortByClick('name')}
            >
              {t('mapViews.table.headers.name')}
            </h4>
            {sortBy.type === 'name' && (
              <FontAwesomeIcon
                icon={
                  sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon
                }
                size='1x'
                className={scss.adminCursor}
                onClick={() => handleSortByClick('name')}
              />
            )}
          </div>

          <div className={layerListScss.emptyGrid}></div>

          <div className={scss.adminBoxRowItems}>
            <h4 className={scss.adminHeadings}>
              {t('mapViews.table.headers.description')}
            </h4>
          </div>

          <div className={layerListScss.emptyGrid}></div>

          <div
            className={scss.adminBoxRowItems}
            style={{
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div className={layerListScss.layerSortContainer}>
              <h4
                className={[
                  layerListScss.adminEditedHeadings,
                  scss.adminHeadings,
                  scss.adminCursor,
                ].join(' ')}
                onClick={() => handleSortByClick('dateUpdated')}
              >
                Last Edited
              </h4>
              {sortBy.type === 'dateUpdated' ? (
                <FontAwesomeIcon
                  icon={
                    sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon
                  }
                  size='1x'
                  className={scss.adminCursor}
                  onClick={() => handleSortByClick('dateUpdated')}
                />
              ) : null}
            </div>
            <div className={layerListScss.layerSortContainer}>
              <h4
                className={[
                  layerListScss.adminEditedHeadings,
                  scss.adminHeadings,
                  scss.adminCursor,
                ].join(' ')}
                onClick={() => handleSortByClick('dateCreated')}
              >
                Created
              </h4>
              {sortBy.type === 'dateCreated' ? (
                <FontAwesomeIcon
                  icon={
                    sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon
                  }
                  size='1x'
                  className={scss.adminCursor}
                  onClick={() => handleSortByClick('dateCreated')}
                />
              ) : null}
            </div>
          </div>

          <div className={layerListScss.emptyGrid}></div>

          {/* <div className={scss.adminBoxRowItems} style={{justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
            <div className={mapViewScss.layerSortContainer}>
              <h4
                className={[mapViewScss.adminEditedHeadings, scss.adminHeadings].join(" ")}
                onClick={() => setSortBy({...sortBy, type: 'dateUpdated'})}
              >
                Last Edited
              </h4>
              {sortBy.type === 'dateUpdated' ? (
                <FontAwesomeIcon
                  icon={ sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon}
                  size="1x"
                  onClick={toggleDirection}
                />
              ) : null}
            </div>
            <div className={mapViewScss.layerSortContainer}>
              <h4
                className={[mapViewScss.adminEditedHeadings, scss.adminHeadings].join(" ")}
                onClick={() => setSortBy({...sortBy, type: 'dateCreated'})}
              >
                Created
              </h4>
              {sortBy.type === 'dateCreated' ? (
                <FontAwesomeIcon
                  icon={ sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon}
                  size="1x"
                  onClick={toggleDirection}
                />
              ) : null}
            </div>
          </div> */}

          <div
            className={scss.adminBoxRowItems}
            style={{ justifyContent: 'center' }}
          >
            <h4
              className={[scss.adminHeadings, scss.adminCursor].join(' ')}
              onClick={() => handleSortByClick('status')}
            >
              {t('mapViews.table.headers.status')}
            </h4>
            {sortBy.type === 'status' ? (
              <FontAwesomeIcon
                icon={
                  sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon
                }
                size='1x'
                className={scss.adminCursor}
                onClick={() => handleSortByClick('status')}
              />
            ) : null}
          </div>

          <div className={layerListScss.emptyGrid}></div>
          <div className={layerListScss.emptyGrid}></div>
          <div className={layerListScss.emptyGrid}></div>
          <div className={layerListScss.emptyGrid}></div>
          <div className={layerListScss.emptyGrid}></div>
        </div>

        {(availableLoading || activeLoading) && (
          <>
            <Skeleton height={88} count={7} />
          </>
        )}
        {!availableLoading && !activeLoading && (
          <MapViewList
            mapViewList={mapViewList}
            setMapViewList={setMapViewList}
            activeMapViewList={activeMapViewList}
            toggleMapViewChecks={toggleMapViewChecks}
            checkMapViewFilter={checkMapViewFilter}
            getCountChecked={getCountChecked}
            getCheckbox={getCheckbox}
            clickCheckbox={clickCheckbox}
            // buildRemoveParams={buildRemoveParams}
            handleMapViewStatus={handleMapViewStatus}
            isActiveGroup={isActiveGroup}
            isActiveMapView={isActiveMapView}
            listArea={listArea}

            // type="available"
            // filter={activeFilter}
            // available={availableLayersAPI}
            // active={activeLayersAPI}
          />
        )}
      </div>
    </>
  )
}
