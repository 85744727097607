import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const isDev = process.env.NODE_ENV === 'development'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    debug: isDev,

    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 60 * 24 * 365,
    },

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: isDev ? { v: Date.now() } : {},
      allowMultiLoading: true,
    },

    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      transEmptyNodeValue: '',
    },

    reloadOnPrerender: isDev,
  })

// Hot reload support
if (isDev && module.hot) {
  module.hot.accept(
    [
      // List your translation files here
      '/public/locales/en/translation.json',
      '/public/locales/es/translation.json',
      // Add other language files as needed
    ],
    () => {
      // Reload all namespaces for all languages
      i18n.reloadResources().then(() => {
        i18n.changeLanguage(i18n.language)
      })
    }
  )
}

export default i18n
